<template>
  <div class="home">
    <van-nav-bar title="详情" left-arrow id="reset" />
    <div class="header">
      <div class="top">
        <img src="../static/客户/touxiang2.png" alt="">
        <p>唐松月</p>
      </div>
      <p class="txt">负责人 钉子</p>
      <p class="txt">最后跟进时间 2021-4-28 12:00:00 </p>
      <p class="txt t1">联系电话 15288888888
        <img src="../static/动态详情1/dianhua.png" class="phone" alt="">
      </p>
    </div>
    <van-tabs v-model="active" title-inactive-color="#8D8D8D" title-active-color="#1583D2" color="#1583D2">
      <van-tab title="沟通日志">
        <div class="text">
          没有更多内容
        </div>
      </van-tab>
      <van-tab title="附件">
        <div class="text">
          没有更多内容
        </div>
      </van-tab>
    </van-tabs>
    <div class="ba">

    </div>
    <div class="footer">
      <div class="fb">
     
          <img src="../static/动态详情2/xierizhi.png" alt="" class="fbimg">
      
 
          <img src="../static/动态详情2/xiansuo.png" alt=""class="fbimg">
     

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.fbimg{
  width: 57px;
  height: 57px;
  border-radius: 5px;
  z-index: 999;
  margin: 15px;
}
.ba{
    height: 100%;
  background: #8b8b8b;
  opacity: 0.5;
  z-index: 10;

  position: fixed;
  bottom: 0;
  width: 100%;
}
.fb {
  height: 200px;
  background: #ffffff;
  display: flex;
  z-index: 999;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.footer {
  // height: 100%;
  // background: #8b8b8b;
  // // opacity: 0.5;
  // z-index: 10;

  // position: fixed;
  // bottom: 0;
  // width: 100%;
}
.text {
  height: 500px;
  background: #ffffff;
}
#reset ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.van-nav-bar {
  background: #1288f4;
}
#reset ::v-deep .van-icon.van-icon-arrow-left.van-nav-bar__arrow {
  color: white !important;
}
.home {
  background: #f2f2f2;
  padding-bottom: 40px;
  height: 100vh;
}
.header {
  height: 125px;
  padding: 15px 25px;
  background: #ffffff;
  margin-bottom: 20px;
  .top {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
    p {
      width: 65px;
      height: 20px;
      font-size: 21px;
      font-weight: normal;
      color: #373c40;
      margin: 0;
    }
  }
}
.txt {
  height: 13px;
  font-size: 14px;
  color: #8d8d8d;
}
.phone {
  width: 14px;
  height: 17px;
  margin-left: 25px;
}
</style>